<template>
  <v-layout style="width: 100%; height: 100%">
    <Home/>
  </v-layout>
</template>
<script>
import Home from '@/components/home/index'
export default {
  components: { Home }
}
</script>
