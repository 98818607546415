import Modes from '@mapbox/mapbox-gl-draw/src/modes'
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants'
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom'
import circle from '@turf/circle'
const CircleMode = {...Modes.draw_polygon}
const DEFAULT_RADIUS_IN_KM = 0.003

CircleMode.onSetup = function (opts) {
  const polygon = this.newFeature({
    type: Constants.geojsonTypes.FEATURE,
    properties: {
      isCircle: true,
      center: []
    },
    geometry: {
      type: Constants.geojsonTypes.POLYGON,
      coordinates: [[]]
    }
  })

  this.addFeature(polygon)

  this.clearSelectedFeatures()
  doubleClickZoom.disable(this)
  this.updateUIClasses({ mouse: Constants.cursors.ADD })
  this.activateUIButton(Constants.types.POLYGON)
  this.setActionableState({
    trash: true
  })

  return {
    initialRadiusInKm: opts.initialRadiusInKm || DEFAULT_RADIUS_IN_KM,
    polygon,
    currentVertexPosition: 0
  }
}

CircleMode.clickAnywhere = function (state, e) {
  if (state.currentVertexPosition === 0) {
    state.currentVertexPosition++
    const center = [e.lngLat.lng, e.lngLat.lat]
    const circleFeature = circle(center, state.initialRadiusInKm)
    state.polygon.incomingCoords(circleFeature.geometry.coordinates)
    state.polygon.properties.center = center
    state.polygon.properties.radiusInKm = state.initialRadiusInKm
  }
  return this.changeMode(Constants.modes.SIMPLE_SELECT, { featureIds: [state.polygon.id] })
}

export default CircleMode
